import styles from './ScaleLines.module.scss'
import { FC } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'

export const ScaleLines: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass(['layout'])}>
      {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, index) => (
        <div key={item} {...styleClass(['line-wrapper'])}>
          <div {...styleClass(['line-bar'])}></div>
          <div {...styleClass(['line-number-wrapper'])}>
            <div {...styleClass(['line-number-text'])}>{item}</div>
          </div>
          <div {...styleClass(['line-bar'])}></div>
        </div>
      ))}
    </div>
  )
}
