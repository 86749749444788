import styles from './Header.module.scss'
import { FC } from 'react'
import { Image } from 'antd'
import logo from '@/assets/images/logo.svg'
import { useStyles } from '@/hooks/styles'

export const Header: FC = () => {
  const styleClass = useStyles(styles)
  return (
    <div className={styles['layout']}>
      <Image width={24} height={24} src={logo} preview={false}></Image>
      <div {...styleClass(['main-title'])}>
        <div {...styleClass(['main-title-text'])}>StyleFactory</div>
      </div>
      <div {...styleClass(['subtitle-text'])}></div>
    </div>
  )
}
