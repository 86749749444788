import styles from './WeightSlider.module.scss'
import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useColorVar, useStyles } from '@/hooks/styles'
import ReactDragResize from 'react-drag-resize-growth'
import { useRoughStore } from '@/hooks'
import Thumb from '@/assets/images/thumb.svg'
import { Image } from 'antd'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { SvgIcon } from '@/components/icons'
import { IconImage, IconText } from '@/components/icons/IconStyleFactory'

export const WeightSlider: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const rough = useRoughStore()

  const { width, height } = { width: 22, height: 18 }
  const [leftValue, setLeftValue] = useState<number>(106)
  const [ifHover, setIfHover] = useState<boolean>(false)

  const handleDragging = ({ curMove }) => {
    setLeftValue(curMove.left)
    rough.setLeftValue(curMove.left as number)
  }

  const calcWeight = (leftValue: number) => {
    const textWeight = (leftValue / 212).toFixed(2)
    return `${textWeight}`
  }

  const handleDragStop = ({ curMove }) => {
    const textWeight = (curMove.left / (curMove.left + curMove.right)).toFixed(
      2
    )
    rough.setWeight([Number(textWeight), 1 - Number(textWeight)])
  }

  return (
    <div {...styleClass(['wrapper'])}>
      <div {...styleClass(['temp'])}>
        <SvgIcon icon={IconText} />
      </div>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['bar-left'], { width: rough.leftValue })}></div>
        <div
          {...styleClass(['bar-right'], { width: 212 - rough.leftValue })}
        ></div>
        <ReactDragResize
          {...styleClass(['drag-item'])}
          w={width}
          h={height}
          x={rough.leftValue}
          axis="x" // 只允许x轴移动
          parentLimitation={true} // 移动限制在父级容器内
          isResizable={false} // 禁止缩放
          onDragging={handleDragging}
          onDragstop={handleDragStop}
        >
          <div
            {...styleClass(['thumb'])}
            onMouseEnter={() => setIfHover(true)}
            onMouseLeave={() => setIfHover(false)}
          >
            <Image
              src={Thumb}
              preview={false}
              draggable={false}
              width={22}
              height={18}
              style={{ marginTop: -5, zIndex: 2 }}
            />
            <motion.div
              {...styleClass(['thumb-content'])}
              initial={{ opacity: 1, top: -16 }}
              // animate={{ opacity: ifHover ? 1 : 0, top: ifHover ? -16 : -12 }}
              // transition={{ ...motionEasing }}
            >
              <div {...styleClass(['thumb-content-text'])}>
                {calcWeight(leftValue)}
              </div>
            </motion.div>
          </div>
        </ReactDragResize>
      </div>
      <div {...styleClass(['temp'])}>
        <SvgIcon icon={IconImage} />
      </div>
    </div>
  )
})
