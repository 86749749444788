import styles from './HomePage.module.scss'
import { useStyles } from '@/hooks/styles'
import { Header } from '@/components/0Header/Header'
import { RoughMachining } from '@/components/1RoughMachining/RoughMachining'
import { StyleLibrary } from '@/components/2StyleLibrary/StyleLibrary'
import { FineMachining } from '@/components/3FineMachining/FineMachining'
import { PersonalizedGeneration } from '@/components/4PersonalizedGeneration/PersonalizedGeneration'

const HomePage = () => {
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['wrapper'])}>
      <div {...styleClass(['layout'])}>
        <Header />
        <div {...styleClass(['content'])}>
          <div {...styleClass(['section-1'])}>
            <RoughMachining />
            <StyleLibrary />
          </div>
          <div {...styleClass(['section-2'])}>
            <FineMachining />
          </div>
          <div {...styleClass(['section-3'])}>
            <PersonalizedGeneration />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
