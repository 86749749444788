import styles from './StyleLibrary.module.scss'
import { FC, useEffect, useRef, useState } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import { observer } from 'mobx-react'
import { ViewTitle } from '@/components/universal/ViewTitle/ViewTitle'
import axios from 'axios'
import { baseUrl } from '@/config'
import { message, Image, Button } from 'antd'
import { useGenStore, useGraphStore, useRoughStore } from '@/hooks'
import {
  addImageNodeInCanvas,
  clearImageInCanvas,
  id2url,
} from '@/components/3FineMachining/utils'
import { ImageNode } from '@/components/3FineMachining/ImageNode'
import { mobxProxyTrans } from '@/utils/basic'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { SvgIcon } from '@/components/icons'
import {
  IconDownArrow,
  IconRightArrow,
} from '@/components/icons/IconStyleFactory'
import { DeleteOutlined } from '@ant-design/icons'
import { IStyle } from '@/store/roughStore'

export const StyleLibrary: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const [messageApi, contextHolder] = message.useMessage()
  const rough = useRoughStore()

  // 拉取风格列表
  const pullStyleList = async () => {
    await axios
      .post(`${baseUrl}/library/pull`)
      .then((res) => {
        // console.log(res.data.data)
        // messageApi.success({ key: 'pull', content: 'Deleted' })
        if (res.data.data.length > 0) {
          // 空数组被传过来是个object
          rough.setStyleList(res.data.data)
        }
        return
      })
      .catch((error) => {
        messageApi.error({ key: 'pull', content: 'Error' })
        console.log('error', error)
        return
      })
  }

  useEffect(() => {
    // rough.styleList.length !== 0 && console.log(mobxProxyTrans(rough.styleList))
  }, [rough.styleList])

  useEffect(() => {
    pullStyleList()
  }, [])

  return (
    <div {...styleClass(['layout'])}>
      {contextHolder}
      <ViewTitle text={'Style Library'} />
      <div {...styleClass(['list'])}>
        {rough.styleList.map((style, index) => (
          <StyleItem index={index} styleMeta={style} key={style.id} />
        ))}
      </div>
    </div>
  )
})

interface IStyleItem {
  index: number // 第几个item
  styleMeta: IStyle // 风格meta数据
}
const StyleItem: FC<IStyleItem> = observer(({ index, styleMeta }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const [messageApi, contextHolder] = message.useMessage()
  const rough = useRoughStore()
  const graphStore = useGraphStore()
  const genStore = useGenStore()

  const [ifExpand, setIfExpand] = useState<boolean>(index === 0)

  const calcReferences = (references: string[]) => {
    const result = [...references]
    if (result.length < 4) {
      for (let i = result.length; i < 4; i++) {
        result.push('')
      }
    }
    return result
  }

  // 点击编辑按钮，画布回到自定义该风格时的状态
  const handleEdit = async () => {
    rough.setCurrentStyleID(styleMeta.id)
    // 左侧视图的参数也给重置了
    rough.setDescription(styleMeta.data.description)
    rough.setReference(calcReferences(styleMeta.data.references))
    // rough.setWeight([0.5,0.5])
    rough.setLeftValue(106)
    graphStore.setTestImage(styleMeta.data.testImage)
    graphStore.setPreviewImage(styleMeta.data.previewImageList)

    genStore.setMode(styleMeta.data.mode)
    genStore.setApplyStyle(styleMeta.data.applyStyle)
    genStore.setStrength(styleMeta.data.strength)
    genStore.setPositivePrompt(styleMeta.data.positivePrompt)
    genStore.setNegativePrompt(styleMeta.data.negativePrompt)
    genStore.setRenderLevel(styleMeta.data.renderLevel)
    genStore.setOriginalImage(styleMeta.data.originalImage)
    genStore.setModificationLevel(styleMeta.data.modificationLevel)
    genStore.setGeneratedImage(styleMeta.data.generatedImage)

    // 清空画布
    clearImageInCanvas(graphStore)
    // 获取该style的所有信息
    const selectedStyle = rough.styleList.find(
      (style) => style.id === styleMeta.id
    )
    const temp = selectedStyle['data']['canvasImageList']
    // console.log(mobxProxyTrans(temp))
    // 渲染这些图片
    for (let i = 0; i < temp.length; i++) {
      addImageNodeInCanvas({
        graph: graphStore.graph,
        imageID: temp[i]['id'],
        component: ({ node, graph }) => {
          node.setData(temp[i]['data'])
          return (
            <ImageNode
              node={node}
              graph={graph}
              imageID={temp[i]['id']}
              url={temp[i]['url']}
            />
          )
        },
        position: temp[i]['position'],
      })
    }
  }

  // 点击删除按钮
  const handleDelete = async () => {
    await axios
      .post(`${baseUrl}/library/delete`, {
        id: styleMeta.id,
      })
      .then((res) => {
        // 删除完了之后，重新拉取style列表数据以更新store中的数组
        rough.deleteStyle(styleMeta.id)
        messageApi.success({ key: 'delete', content: 'Deleted' })
        return
      })
      .catch((error) => {
        messageApi.error({ key: 'delete', content: 'Error' })
        console.log('error', error)
        return
      })
  }

  const calcImageToShow = (imageList: string[]) => {
    const result = []
    if (imageList.length <= 6) {
      return imageList
    } else {
      result.push(imageList[0]) // result[0]
      result.push(imageList[1]) // result[1]
      result.push(imageList[2]) // result[2]
      result.push(imageList[imageList.length - 3]) // result[3]
      result.push(imageList[imageList.length - 2]) // result[4]
      result.push(imageList[imageList.length - 1]) // result[5]
      return result
    }
  }

  return (
    <motion.div
      {...styleClass(['item-layout'])}
      initial={{ height: index === 0 ? 202 : 106 }}
      animate={{ height: ifExpand ? 202 : 106 }}
      transition={{ ...motionEasing, duration: 0.3 }}
    >
      {contextHolder}
      <div {...styleClass(['item-library'])}>
        <Image
          src={id2url(styleMeta.image_list[0])}
          width={60}
          height={60}
          style={{ borderRadius: 2, overflow: 'hidden' }}
        ></Image>
        <div {...styleClass(['item-library-middle'])}>
          <div {...styleClass(['item-library-middle-up'])}>
            <SvgIcon icon={IconRightArrow} />
          </div>
          <div {...styleClass(['item-library-middle-down'])}>
            {calcImageToShow(styleMeta.image_list).map(
              (item, index) =>
                index >= 1 &&
                index < 5 && (
                  <div
                    key={item}
                    {...styleClass(['item-library-middle-down-item'])}
                  >
                    <Image
                      src={id2url(item)}
                      width={34}
                      height={34}
                      style={{ borderRadius: 1, overflow: 'hidden' }}
                    ></Image>
                  </div>
                )
            )}
          </div>
        </div>
        <Image
          src={id2url(styleMeta.image_list[styleMeta.image_list.length - 1])}
          width={60}
          height={60}
          style={{ borderRadius: 2, overflow: 'hidden' }}
        ></Image>
      </div>
      <div {...styleClass(['item-tools'])}>
        <div
          {...styleClass(['item-tools-left'])}
          onClick={() => setIfExpand(!ifExpand)}
        >
          <motion.div
            {...styleClass(['item-tools-left-icon'])}
            animate={{ rotateX: ifExpand ? '180deg' : '0deg' }}
            transition={{ ...motionEasing, duration: 0.3 }}
          >
            <SvgIcon icon={IconDownArrow} />
          </motion.div>
          <div {...styleClass(['item-tools-left-text'])}>{`Show ${
            ifExpand ? 'less' : 'more'
          }`}</div>
        </div>

        <div {...styleClass(['item-tools-right'])}>
          <Button
            {...styleClass(['item-tools-right-button'], { padding: '0 11px' })}
            onClick={() => genStore.setApplyStyle(styleMeta.id)}
          >
            Add
          </Button>
          <Button
            type={'text'}
            {...styleClass(['item-tools-right-button'], {
              padding: '0 4px',
              marginLeft: 12,
            })}
            onClick={() => handleEdit()}
          >
            Edit
          </Button>
          <Button
            type={'text'}
            {...styleClass(['item-tools-right-button'], { padding: '0 4px' })}
            onClick={() => handleDelete()}
          >
            <DeleteOutlined rev={'zmx'} />
          </Button>
        </div>
      </div>

      <motion.div
        {...styleClass(['item-info'])}
        animate={{ opacity: ifExpand ? 1 : 0, y: ifExpand ? 0 : -6 }}
        transition={{ ...motionEasing, duration: 0.3 }}
      >
        <div {...styleClass(['item-info-desc'])}>
          <div {...styleClass(['item-info-desc-title'])}>Description</div>
          <div {...styleClass(['item-info-desc-text'])}>
            {styleMeta.data['description']}
          </div>
        </div>
        <div {...styleClass(['item-info-ref'])}>
          <div {...styleClass(['item-info-ref-title'])}>References</div>
          <div {...styleClass(['item-info-ref-images'])}>
            {styleMeta.data.references.map(
              (item, index) =>
                item !== '' && (
                  <Image
                    key={item}
                    src={item}
                    width={32}
                    height={32}
                    style={{ borderRadius: 2, overflow: 'hidden' }}
                  />
                )
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
})
