import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'

interface IUserInfo {
  id: number // 用户ID
  nickName?: string // 用户昵称
  avatar?: string // 用户头像图片url
  token?: string
}

export default class UserStore {
  public context: RootContext

  public userStatus
  public userInfo: IUserInfo = {
    id: 0,
    nickName: 'Teddy',
    avatar: 'https://s3.bmp.ovh/imgs/2023/08/19/a47468834ad28c16.png',
  }

  constructor(context: RootContext) {
    this.context = context
    this.userStatus = this.getUserStatus()
    makeAutoObservable(this, {}, { autoBind: true })
  }

  // 设置用户登录状态
  setUserStatus = (newStatus: boolean) => {
    const data = newStatus ? 'true' : 'false'
    this.userStatus = newStatus
    localStorage.setItem('userStatus', data)
  }

  // 获取用户登录状态
  getUserStatus = (): boolean => {
    const result = localStorage.getItem('userStatus')
    return result === 'true'
  }

  setUserInfo = (info: IUserInfo) => {
    this.userInfo = { ...this.userInfo, ...info }
    localStorage.setItem('token', info.token)
    localStorage.setItem('id', `${info.id}`)
  }
}
