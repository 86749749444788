import styles from './App.module.scss'
import './global.scss'
import './overlay.scss'
import './i18n'
import 'animate.css/animate.css'
import React, { useCallback, useEffect, useState } from 'react'
import { AppContext, RootContext } from '@/App.context'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { ConfigProvider, theme } from 'antd'
import { useLang } from '@/hooks/i18n'
import { useForceUpdate } from '@/hooks'
import commonLang from '@/locale/common'
import { useColorVar, useStyles } from '@/hooks/styles'
import { router } from '@/router'
import 'nprogress/nprogress.css'
import { ScrollerMotion } from 'scroller-motion'
import { Portal } from '@antv/x6-react-shape'

// https://x6.antv.antgroup.com/tutorial/intermediate/react#portal-%E6%96%B9%E5%BC%8F
const X6ReactPortalProvider = Portal.getProvider() // 注意，一个 graph 只能申明一个 portal provider

const App = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const { t, i18n } = useLang(commonLang, 'common')
  const [, forceUpdate] = useForceUpdate()
  const [context] = useState<RootContext>(
    () => new RootContext({ t, i18n }, forceUpdate)
  )
  const rootStore = context.store

  const element = useRoutes(router)

  useEffect(() => {
    /* const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    if (isChrome) {
      document.documentElement.classList.add('chrome-hide-scrollbar')
    } else {
      document.documentElement.classList.add('safari-show-scrollbar')
    } */
  }, [])

  return (
    <>
      {/* Antd 全局样式 */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: c('primary'),
            colorPrimaryHover: '#d0c9ac', // -9
          },
          algorithm: theme.defaultAlgorithm,
        }}
      >
        {/* app上下文 */}
        <AppContext.Provider value={context}>
          {/* 统一画布元素至app上下文 */}
          <X6ReactPortalProvider />
          <ScrollerMotion disabled>{element}</ScrollerMotion>
        </AppContext.Provider>
      </ConfigProvider>
    </>
  )
}

export default App
