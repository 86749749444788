import styles from './ImageList.module.scss'
import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useStyles } from '@/hooks/styles'
import { useRoughStore } from '@/hooks'
import { Image, Modal } from 'antd'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'

export const ImageList: FC = observer(() => {
  const styleClass = useStyles(styles)
  const rough = useRoughStore()
  return (
    <div {...styleClass(['layout'])}>
      {rough.reference.map((item, index) => (
        <ImageItem key={index} url={item} index={index} />
      ))}
    </div>
  )
})

interface IImageItem {
  url: string
  index: number
}

export const ImageItem: FC<IImageItem> = ({ url, index }) => {
  const styleClass = useStyles(styles)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const rough = useRoughStore()
  return (
    <div {...styleClass(['item-layout'])}>
      {url !== '' && (
        <>
          <Image src={url} {...styleClass(['item-img'])} preview={false} />
          <motion.div
            {...styleClass(['item-mask'])}
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            transition={{ ...motionEasing }}
          >
            <EyeOutlined
              rev={null}
              {...styleClass(['item-mask-icon'])}
              onClick={() => {
                setPreviewOpen(true)
                setPreviewImage(url)
              }}
            />
            <DeleteOutlined
              rev={null}
              {...styleClass(['item-mask-icon'])}
              onClick={() => rough.deleteReference(index)}
            />
          </motion.div>
        </>
      )}
      {url === '' && (
        <Image
          src={'https://s3.bmp.ovh/imgs/2024/03/12/a4aecd731a9333e7.png'}
          preview={false}
          width={56}
          height={56}
        />
      )}
      <Modal
        open={previewOpen}
        title={'Preview'}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

interface IImageItemForTest {
  url: string
  index: number
  onDelete?: () => void
}

export const ImageItemForTest: FC<IImageItemForTest> = ({
  url,
  index,
  onDelete,
}) => {
  const styleClass = useStyles(styles)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  return (
    <div {...styleClass(['item-layout'], { padding: 0 })}>
      {url !== '' && (
        <>
          <Image src={url} {...styleClass(['item-img'])} preview={false} />
          <motion.div
            {...styleClass(['item-mask'], { width: 64, height: 64 })}
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            transition={{ ...motionEasing }}
          >
            <EyeOutlined
              rev={null}
              {...styleClass(['item-mask-icon'])}
              onClick={() => {
                setPreviewOpen(true)
                setPreviewImage(url)
              }}
            />
            <DeleteOutlined
              rev={null}
              {...styleClass(['item-mask-icon'])}
              onClick={() => onDelete && onDelete()}
            />
          </motion.div>
        </>
      )}
      {url === '' && (
        <Image
          src={'https://s3.bmp.ovh/imgs/2024/03/12/a4aecd731a9333e7.png'}
          preview={false}
          width={64}
          height={64}
        />
      )}
      <Modal
        open={previewOpen}
        title={'Preview'}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}
