import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'

export default class GenStore {
  public context: RootContext

  public mode: 'Prompt' | 'Image' = 'Prompt' // 渲染模式
  public applyStyle = null // 要应用的风格id
  public strength = 0.5 // 风格强度
  // prompt模式
  public positivePrompt = 'Positive: sunrise by the seaside'
  public negativePrompt = 'Negative: '
  public renderLevel = 40 // 风格渲染程度
  // image模式
  public originalImage = ''
  public modificationLevel = 70 // 原图修改程度

  public generatedImage = ['', '', '', '']

  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setMode = (mode: 'Prompt' | 'Image') => {
    this.mode = mode
  }
  setApplyStyle = (id: string | null) => {
    this.applyStyle = id
  }
  setStrength = (v: number) => {
    this.strength = v
  }
  setPositivePrompt = (input: string) => {
    if (input.indexOf('Positive: ') === -1) {
      return
    }
    this.positivePrompt = input
  }
  getPositivePrompt = () => {
    return this.positivePrompt.replace(/Positive: /g, '')
  }
  setNegativePrompt = (input: string) => {
    if (input.indexOf('Negative: ') === -1) {
      return
    }
    this.negativePrompt = input
  }
  getNegativePrompt = () => {
    return this.negativePrompt.replace(/Negative: /g, '')
  }

  setRenderLevel = (v) => {
    this.renderLevel = v
  }

  setOriginalImage = (url: string) => {
    this.originalImage = url
  }

  setModificationLevel = (v) => {
    this.modificationLevel = v
  }

  setGeneratedImage = (images: string[]) => {
    this.generatedImage = [...images]
  }
}
