import { RootContext } from '@/App.context'
import { CSSProperties, useCallback } from 'react'
import classNames from 'classnames/bind'

interface colorType {
  colorNames:
    | 'white'
    | 'primary'
    | 'system-bg'
    | 'text-black'
    | 'text-gray'
    | 'light'
    | 'border'
    | 'axis'
}
const colorVars: Record<string, string> = {
  'color-white': '#FFFFFF',
  'color-primary': '#C5BC98',
  'color-system-bg': '#F2F2F2',
  'color-text-black': '#333333',
  'color-text-gray': '#7A7C86',
  'color-light': '#F7CB8A',
  'color-border': '#D9D9D9',
  'color-axis': '#CEC3BE',
}

export const useColorVar = (context?: RootContext) => {
  return useCallback((name: colorType['colorNames']) => {
    return colorVars['color-' + name]
  }, [])
}

export const useStyles = (
  CSSStyles: {
    [key: string]: string | CSSProperties
  } = {}
) => {
  interface IStyleClass {
    className?: string
    style?: CSSProperties
  }
  const cx = classNames.bind(CSSStyles)
  return useCallback(
    (
      classes: (string | Record<string, boolean>)[],
      styles: any = [],
      props: IStyleClass = {}
    ) => {
      const flattenStyles = (stylesToFlatten: any[]): any => {
        const styleObject = {}
        const animationStyles: any[] = []
        stylesToFlatten.forEach((item) => {
          if (typeof item !== 'object') return
          if (item.viewDescriptors) {
            animationStyles.push(item)
          } else {
            Object.assign(styleObject, item)
          }
        })
        if (animationStyles.length > 0) {
          return [styleObject, ...animationStyles]
        } else {
          return styleObject
        }
      }

      let propsStyle = props.style || []
      if (typeof propsStyle === 'string') {
        throw 'no support for CSS string yet'
      }

      // 优先使用 style 数组进行预处理
      if (!Array.isArray(propsStyle)) {
        propsStyle = [propsStyle]
      }

      let currentStyle = styles
      // 优先使用 style 数组进行预处理
      if (!Array.isArray(currentStyle)) {
        currentStyle = [currentStyle]
      }

      const rawClassNames: string[] = []
      classes.map((arg) => {
        if (typeof arg === 'string') {
          rawClassNames.push(arg)
        } else {
          for (const argKey in arg) {
            arg[argKey] && rawClassNames.push(argKey)
          }
        }
      })

      const finalClassNames: string[] = []
      rawClassNames.map((className) => {
        finalClassNames.push(className)
        /* if (darkMode && CSSStyles[className + '_dark']) {
          finalClassNames.push(className + '_dark')
        }

        if (IS_H5 && CSSStyles[className + '_h5']) {
          finalClassNames.push(className + '_h5')
          if (darkMode && CSSStyles[className + '_h5_dark']) {
            finalClassNames.push(className + '_h5_dark')
          }
        }
        if (IS_WEAPP && CSSStyles[className + '_weapp']) {
          finalClassNames.push(className + '_weapp')
          if (darkMode && CSSStyles[className + '_weapp_dark']) {
            finalClassNames.push(className + '_weapp_dark')
          }
        } */
      })
      return {
        className:
          cx(...finalClassNames) +
          (props.className ? ` ${props.className}` : ''),
        style: flattenStyles([...currentStyle, ...propsStyle]),
      }
    },
    []
  )
}
