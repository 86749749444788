import { CSSProperties, FC } from 'react'
import styles from './ViewTitle.module.scss'
import { useStyles } from '@/hooks/styles'

interface IViewTitle {
  text?: string
  style?: CSSProperties
  className?: string
}

export const ViewTitle: FC<IViewTitle> = ({ text = '', style, className }) => {
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['wrapper'], {}, { style, className })}>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['text'])}>{text}</div>
      </div>
    </div>
  )
}
