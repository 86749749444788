import styles from './BlockTitle.module.scss'
import { CSSProperties, FC, ReactNode } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'

interface IBlockTitle {
  icon?: ReactNode
  title?: string
  description?: string
  barWidth?: number
  style?: CSSProperties
  className?: string
}

export const BlockTitle: FC<IBlockTitle> = ({
  icon,
  title,
  description,
  barWidth = 70,
  style,
  className,
}) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass(['layout'], {}, { style, className })}>
      <div {...styleClass(['icon-wrapper'])}>{icon}</div>
      <div {...styleClass(['title-wrapper'])}>
        <div {...styleClass(['title-text'])}>{title}</div>
        <div {...styleClass(['title-bar'], { width: barWidth })}></div>
      </div>
      {description && (
        <div {...styleClass(['description-wrapper'])}>
          <div {...styleClass(['description-text'])}>{description}</div>
        </div>
      )}
    </div>
  )
}
