import { FC, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import styles from './MainGraph.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useGraphStore } from '@/hooks'
import { Graph } from '@antv/x6'
import {
  graphPlugInInit,
  gridOptions,
} from '@/components/3FineMachining/GraphOptions'
import {
  addImageNodeInCanvas,
  addNodeInCanvas,
  clearImageInCanvas,
  nodeInTrackControl,
} from '@/components/3FineMachining/utils'
import { ImageNode } from '@/components/3FineMachining/ImageNode'
import { GraphMark } from '@/components/3FineMachining/FineMachining'
import { mobxProxyTrans } from '@/utils/basic'

export const MainGraph: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const refContainer = useRef()
  const refGraph = useRef()

  const graphStore = useGraphStore()

  useEffect(() => {
    const containerDom = refContainer.current as HTMLElement

    graphStore.graph = new Graph({
      container: refGraph.current,
      grid: { ...gridOptions },
      width: containerDom.clientWidth,
      height: containerDom.clientHeight,
      background: {
        color: c('white'),
      },
      virtual: true,
      /* scaling: {
        min: 0.05, // 最小缩放比例,
        max: 5, // 最大缩放比例
      }, */
      /* panning: {
        ...panningOptions, // CTRL/ALT 平移
      }, */
      mousewheel: {
        // ...mousewheelOptions, // CTRL/ALT 缩放
      },
      connecting: {
        // ...connectingOptions, // 连线的约束规则
      },
      highlighting: {
        // ...highlightingOptions, // 连接桩连线时交互高亮
      },
      interacting: {
        // ...interactingOptions(graphStore),
      },
    })

    // 插件初始化
    graphPlugInInit(graphStore.graph, graphStore)
    nodeInTrackControl(graphStore.graph, graphStore)

    return () => {
      // 销毁
      graphStore.graph.dispose()
    }
  }, [])

  useEffect(() => {
    addNodeInCanvas({
      graph: graphStore.graph,
      component: ({ node, graph }) => <GraphMark node={node} />,
      size: [1154, 686],
      position: [0, 0],
    })

    /* addImageNodeInCanvas({
      graph: graphStore.graph,
      imageID: 'test1-1',
      component: ({ node, graph }) => (
        <ImageNode node={node} graph={graph} imageID={'test'} />
      ),
      position: [72, 278],
    })
    addImageNodeInCanvas({
      graph: graphStore.graph,
      imageID: 'test1-2',
      component: ({ node, graph }) => (
        <ImageNode node={node} graph={graph} imageID={'test'} />
      ),
      position: [72, 362],
    })
    addImageNodeInCanvas({
      graph: graphStore.graph,
      imageID: 'test2-1',
      component: ({ node, graph }) => (
        <ImageNode node={node} graph={graph} imageID={'test'} />
      ),
      position: [72 + 104, 278],
    }) */
  }, [])

  /* useEffect(() => {
    // 注意，该useEffect仅在左侧视图点击生成后才会触发，graphStore.imageData并不代表当前画布中的图片
    // // 清空之前画布中的图片

    const currentNodes = graphStore.graph.getNodes()
    console.log(currentNodes)
    const temp = graphStore.imageData
    console.log(graphStore.imageData.length)
    for (let i = currentNodes.length - 1; i < temp.length; i++) {
      addImageNodeInCanvas({
        graph: graphStore.graph,
        imageID: temp[i]['id'],
        component: ({ node, graph }) => (
          <ImageNode
            node={node}
            graph={graph}
            imageID={temp[i]['id']}
            url={temp[i]['url']}
          />
        ),
        position: temp[i]['position'],
      })
    }
  }, [graphStore.imageData]) */
  return (
    <div ref={refContainer} {...styleClass(['layout'])}>
      <div id={'graph'} ref={refGraph} {...styleClass(['graph'])}></div>
    </div>
  )
})
