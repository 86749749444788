import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'
import { Graph } from '@antv/x6'
import { addImageNodeInCanvas } from '@/components/3FineMachining/utils'
import { ImageNode } from '@/components/3FineMachining/ImageNode'

export default class GraphStore {
  public context: RootContext

  public graph: Graph
  // 上传的测试图片
  public testImage = ''
  // 测试图片的不同风格强度变体
  public previewImageList = [
    'https://s3.bmp.ovh/imgs/2024/03/12/fd7070094fa83ed7.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/48e052777b444708.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/409db9f347981538.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/843f9c2ccedd6819.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/195ab11d432c245d.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/d306a51766047e85.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/08033e9a39bba75f.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/0b51ded358582974.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/113ad6aeada26dde.png',
    'https://s3.bmp.ovh/imgs/2024/03/12/6b08ceeb1ea3b76a.png',
  ]
  // 图片进入轨道的提示
  public imageInTrackMark = false

  public imageData: Array<{
    id: string
    url: string
    position: [number, number]
  }> = []
  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setTestImage = (url: string) => {
    this.testImage = url
  }
  deleteTestImage = () => {
    this.testImage = ''
  }
  // 实时测试图片列表
  setPreviewImage = (urls: string[]) => {
    this.previewImageList = [...urls]
  }

  setImageInTrackMark = (inTrack: boolean) => {
    this.imageInTrackMark = inTrack
  }

  setImageData = (imageList: Array<{ id: string; url: string }>) => {
    const temp = []
    for (let i = 0; i < imageList.length; i++) {
      temp.push({
        id: imageList[i]['id'],
        url: imageList[i]['url'],
        position: [72 + (i % 10) * 104, 278 + Math.trunc(i / 10) * 86],
      })
    }

    this.imageData = [...temp]
  }

  // 2024.7.2
  /* addImageData = (url: string) => {
    const temp = this.graph.getNodes()
    addImageNodeInCanvas({
      graph: this.graph,
      imageID: url,
      component: ({ node, graph }) => (
        <ImageNode node={node} graph={graph} imageID={url} url={url} />
      ),
      position: [
        72 + (temp.length % 10) * 104,
        278 + Math.trunc(temp.length / 10) * 86,
      ],
    })
  } */

  // 2024.7.5
  removeImage = (id: string) => {
    const temp = this.graph.getNodes()
    const imageNode = temp.find((node) => node.id === id)
    this.graph.removeNode(imageNode.id)
  }
}
