import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'

export interface IStyle {
  id: string | null
  image_list: string[]
  weight: number[]
  data: {
    description: string
    references: string[]
    weight: number
    testImage: string
    previewImageList: string[]
    canvasImageList: any[]
    mode: 'Prompt' | 'Image'
    applyStyle: null | string
    strength: number
    positivePrompt: string
    negativePrompt: string
    renderLevel: number
    originalImage: string
    modificationLevel: number
    generatedImage: string[]
  }
}

export default class RoughStore {
  public context: RootContext

  public description = '' // 风格描述
  public reference = [
    // 风格参考图
    '',
    '',
    '',
    '',
  ]
  public weight = [0.5, 0.5] // 文本描述与参考图的混合比重
  public leftValue = 106 // // 文本描述与参考图混合比重（左侧柱状图的宽度）

  public styleList: IStyle[] = [] // 管理Style Library的数据
  public currentStyleID: null | string = null

  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setDescription = (desc: string) => {
    this.description = desc
  }

  addReference = (url: string) => {
    const temp = [...this.reference]
    temp.unshift(url)
    this.reference = temp.slice(0, 4)
  }
  deleteReference = (index: number) => {
    const temp = [...this.reference]
    temp[index] = ''
    for (let i = index; i < temp.length - 1; i++) {
      if (temp[i + 1] !== '') {
        const p = temp[i + 1]
        temp[i] = p
        temp[i + 1] = ''
      }
    }
    this.reference = [...temp]
  }
  setReference = (references: string[]) => {
    this.reference = [...references]
  }
  resetReference = () => {
    const temp = ['', '', '', '']
    this.reference = [...temp]
  }

  setWeight = (weight: [number, number]) => {
    this.weight = [...weight]
  }
  setLeftValue = (value: number) => {
    this.leftValue = value
  }

  setStyleList = (styles: []) => {
    this.styleList = [...styles]
  }
  addStyle = (style: any) => {
    this.styleList.push(style)
    this.styleList = [...this.styleList]
  }
  deleteStyle = (styleID: string) => {
    const result = this.styleList.filter((item) => item.id !== styleID)
    this.styleList = [...result]
  }
  replaceStyle = (style: any) => {
    const result = [...this.styleList]
    for (let i = 0; i < result.length; i++) {
      if (result[i].id === style.id) {
        result[i] = style
        break
      }
    }
    this.styleList = [...result]
  }

  setCurrentStyleID = (id: string | null) => {
    this.currentStyleID = id
  }
}
