import { Cell, Graph } from '@antv/x6'
import { Snapline } from '@antv/x6-plugin-snapline'
import { Clipboard } from '@antv/x6-plugin-clipboard'
import { Selection } from '@antv/x6-plugin-selection'
import { History } from '@antv/x6-plugin-history'
import { Keyboard } from '@antv/x6-plugin-keyboard'
import GraphStore from '@/store/graphStore'

export const graphPlugInInit = (graph: Graph, graphStore: GraphStore) => {
  // 对齐线
  // graph.use(new Snapline(snaplineOptions))
  // 复制粘贴
  graph.use(new Clipboard(clipBoardOptions))
  // 框选
  graph.use(new Selection(selectionOptions(graph)))
  // 撤销重做
  graph.use(new History(historyOptions))
  // 快捷键
  graph.use(new Keyboard(keyboardOptions))
  customKeyBind(graph, graphStore)
}

// https://x6.antv.antgroup.com/tutorial/plugins/snapline

const snaplineOptions = {
  // className: styles['test'],
  enabled: true,
  tolerance: 10,
  sharp: false,
  resizing: true,
  clean: false,
}

// https://x6.antv.antgroup.com/tutorial/plugins/clipboard

const clipBoardOptions = {
  enabled: true,
  useLocalStorage: false,
}

// https://x6.antv.antgroup.com/tutorial/plugins/selection

const selectionOptions = (targetGraph: Graph) => {
  return {
    enabled: true,
    multiple: true,
    // modifiers: ['ctrl'] as ModifierKey[], // 按住ctrl才可框选
    // multipleSelectionModifiers: ['ctrl'] as ModifierKey[], // 按住ctrl点击才可多选
    strict: false,
    rubberband: true,
    movable: true,
    showNodeSelectionBox: true,
    showEdgeSelectionBox: true,
    // 如果打开 showNodeSelectionBox 时，会在节点上方盖一层元素，导致节点的事件无法响应，此时可以配置 pointerEvents: none 来解决
    pointerEvents: 'none' as 'none' | 'auto',
    filter: (cell: Cell) => {
      if (cell.id === 'mark') {
        return false
      }
      return true
    },
  }
}

// https://x6.antv.antgroup.com/tutorial/plugins/keyboard

const keyboardOptions = {
  enabled: true,
}
const customKeyBind = (targetGraph: Graph, graphStore: GraphStore) => {
  targetGraph.bindKey('ctrl+z', () => {
    targetGraph.undo() // 撤销
    // messageApi.info({ content: '撤销' })
  })
  targetGraph.bindKey('ctrl+y', () => {
    targetGraph.redo() // 重做
    // messageApi.info({ content: '重做' })
  })
  targetGraph.bindKey('delete', () => {
    // 删除
    // messageApi.info({ content: '已删除组件' })
  })
  targetGraph.bindKey('ctrl+c', () => {
    const cells = targetGraph.getSelectedCells()
    if (cells.length) {
      targetGraph.copy(cells) // 复制
      // messageApi.info({ content: '已复制至剪贴板' })
    }
    return false
  })
  targetGraph.bindKey('ctrl+v', () => {
    if (!targetGraph.isClipboardEmpty()) {
      const cells = targetGraph.paste({ offset: { dx: 96, dy: 96 } })
      targetGraph.cleanSelection()
      targetGraph.select(cells) // 粘贴
      // messageApi.info({ content: '粘贴' })
    }
    return false
  })
}

// https://x6.antv.antgroup.com/tutorial/plugins/history#%E9%85%8D%E7%BD%AE

const historyOptions = {
  enabled: true,
}

// 网格options https://x6.antv.antgroup.com/api/graph/grid#size
export const gridOptions = {
  visible: true,
  size: 10,
  type: 'doubleMesh',
  args: [
    {
      color: 'rgba(197,197,197,0.15)', // 主网格线颜色
      thickness: 1, // 主网格线宽度
    },
    {
      color: 'rgba(197,197,197,0.4)', // 次网格线颜色
      thickness: 1, // 次网格线宽度
      factor: 4, // 主次网格线间隔
    },
  ],
}
