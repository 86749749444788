import { cubicBezier } from '@motionone/easing'

const isDev = process.env.NODE_ENV === 'development'

const i18n = {}

const baseUrl = 'https://style-factory.idlight.cn'
const uploadUrl = 'https://bsd.idlight.cn/api/admin/storage/index/image/upload'
const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc1JlZnJlc2giOmZhbHNlLCJyb2xlSWRzIjpbIjEiXSwidXNlcm5hbWUiOiJ5YW94aW4iLCJ1c2VySWQiOjMzLCJwYXNzd29yZFZlcnNpb24iOjQsImlhdCI6MTcwODU4NDA2MCwiZXhwIjoxOTA4NTkxMjYwfQ.bVKJMyV5DbcV3AffL0eliDfZlkeFul3AD9BmB--6pHU'

enum Lang {
  system = 'system',
  zh = 'zh-CN',
  en = 'en',
}

const supportedLang: Lang[] = [Lang.system, Lang.zh, Lang.en]

enum Theme {
  system = 'system',
  light = 'light',
  dark = 'dark',
}

const supportedTheme: Theme[] = [Theme.system, Theme.light, Theme.dark]

const motionEasing = {
  duration: 0.2,
  ease: cubicBezier(0.4, 0, 0.2, 1),
}

export {
  baseUrl,
  uploadUrl,
  token,
  isDev,
  i18n,
  Lang,
  supportedLang,
  Theme,
  supportedTheme,
  motionEasing,
}

const testDesc =
  'Thick geometric lines, graphic color blocks, layout rules, and small area filling'
