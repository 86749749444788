import { RouteObject, useNavigate } from 'react-router-dom'
import { FC, lazy, ReactNode, Suspense } from 'react'

import Home from '@/pages/home/Home'
import HomePage from '@/pages/homepage/HomePage'
// const Graph = lazy(() => import('@/pages/graph/Graph'))

const lazyLoad = (children: ReactNode): ReactNode => {
  return <Suspense fallback={<div>loading</div>}>{children}</Suspense>
}

export const router: RouteObject[] = [
  {
    path: '/home',
    element: <Home />,
    children: [
      // { path: '/sustainKG', element: <SustainKG /> },
      // { path: '/graph/:projectID', element: lazyLoad(<Graph />) },
    ],
  },
  {
    path: '',
    element: <HomePage />,
  },
  /* {
    path: '/login',
    element: <Login />,
  }, */
]
