import { makeAutoObservable } from 'mobx'
import { RootContext } from '@/App.context'
import AppStore from '@/store/app'
import UserStore from '@/store/userStore'
import RoughStore from '@/store/roughStore'
import GraphStore from '@/store/graphStore'
import GenStore from '@/store/genStore'

export class RootStore {
  public context: RootContext
  public app: AppStore
  public user: UserStore
  public rough: RoughStore
  public graph: GraphStore
  public gen: GenStore

  constructor(context: RootContext) {
    this.context = context
    this.app = new AppStore(this.context)
    this.user = new UserStore(this.context)
    this.rough = new RoughStore(this.context)
    this.graph = new GraphStore(this.context)
    this.gen = new GenStore(this.context)
    makeAutoObservable(this)
  }
}
