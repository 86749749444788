import React from 'react'
import { useStyles } from '@/hooks/styles'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import styles from './Home.module.scss'

const { Content } = Layout

const Home = () => {
  const styleClass = useStyles(styles)

  return (
    <Layout {...styleClass([], { width: '100vw', minHeight: '100vh' })}>
      <Content {...styleClass([], { width: '100%', height: '100%' })}>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default Home
