import styles from './AxisControl.module.scss'
import { FC, useEffect, useRef, useState } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useGenStore, useRoughStore } from '@/hooks'
import { observer } from 'mobx-react'
import { mobxProxyTrans } from '@/utils/basic'
import { Image, Popover } from 'antd'
import { id2url } from '@/components/3FineMachining/utils'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { SvgIcon } from '@/components/icons'
import { IconCursor } from '@/components/icons/IconStyleFactory'

export const AxisControl: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const contentRef = useRef()
  const genStore = useGenStore()
  const rough = useRoughStore()
  const [imageList, setImageList] = useState([])
  const [weightList, setWeightList] = useState([])

  // 计算图片距离左侧的px
  const calcPos = (imgSize: number, weight: number) => {
    const totalW = 302 // 362 - 60
    return totalW * weight
  }

  const calcStrength = (e) => {
    const content = contentRef.current as HTMLElement
    const strength = Number(
      ((e.clientX - content.getBoundingClientRect().left) / 362).toFixed(2)
    )
    genStore.setStrength(strength)
  }

  useEffect(() => {
    if (genStore.applyStyle === null) {
      setImageList([])
      setWeightList([])
      return
    }
    const styleMeta = rough.styleList.filter(
      (item) => item.id === genStore.applyStyle
    )[0]
    setImageList(styleMeta.image_list)
    setWeightList(styleMeta.weight)
  }, [genStore.applyStyle])

  return (
    <div {...styleClass(['layout'])}>
      <div {...styleClass(['sider'], { left: 0 })}></div>
      <div {...styleClass(['sider'], { right: 0 })}></div>
      <div
        {...styleClass(['content'])}
        ref={contentRef}
        onClick={(e) => calcStrength(e)}
      >
        <div
          {...styleClass(['content-cursor'], {
            left: genStore.strength * 362 - 3,
          })}
        >
          <SvgIcon icon={IconCursor} />
        </div>
        {imageList.length !== 0 && (
          <>
            <div
              {...styleClass(['content-img-wrapper'], {
                left: calcPos(60, weightList[0]),
                overflow: 'hidden',
              })}
            >
              <Image
                src={id2url(imageList[0])}
                width={56}
                height={56}
                draggable={false}
              />
            </div>
            <div
              {...styleClass(['content-img-wrapper'], {
                left: calcPos(60, weightList[imageList.length - 1]),
                overflow: 'hidden',
              })}
            >
              <Image
                src={id2url(imageList[imageList.length - 1])}
                width={56}
                height={56}
                draggable={false}
              />
            </div>

            {imageList.map(
              (item, index) =>
                index > 0 &&
                index < imageList.length - 1 && (
                  <div
                    key={`${item}-index`}
                    {...styleClass(['content-img-wrapper'], {
                      left: calcPos(10, weightList[index]),
                    })}
                  >
                    <Popover
                      content={
                        <Image
                          src={id2url(imageList[index])}
                          style={{ borderRadius: 3, overflow: 'hidden' }}
                          width={60}
                          height={60}
                          preview={false}
                          draggable={false}
                        />
                      }
                    >
                      <motion.div
                        {...styleClass(['content-dot'], {
                          background: `rgba(216,211,192,${
                            0.1 + 0.7 * weightList[index]
                          })`,
                        })}
                        whileHover={{ scale: 1.2, y: -3 }}
                        transition={{ ...motionEasing }}
                      ></motion.div>
                    </Popover>
                  </div>
                )
            )}
          </>
        )}
      </div>
    </div>
  )
})
